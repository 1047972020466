.profile_wrapper {
  width: 100vw;
  height: calc(100vh - 60px);
  padding: 0 40px;
  overflow-y: auto;
  overflow-x: hidden;
}
.profile_header {
  min-height: 300px;
  height: 300px;
  width: 100%;
  padding-top: 5px;
  position: relative;
}
.profile_header img {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
}
.user_avatar {
  width: 160px;
  height: 160px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 100%;
  left: 50px;
  border: 2.23704px solid #ffffff;
  transform: translateY(-50%);
}
.user_avatar img {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
}
.profile_content {
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 50px;
}
.profile_btn_row {
  display: flex;
  justify-content: end;
  padding: 2rem 0;
}

.profile_sidebar_right {
  padding-top: 2rem;
}
.profile_sidebar_card {
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1) !important;
  border-radius: 8px !important;
}
