.img_preview_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid #97a8be;
  border-radius: 8px;
}
.img_preview_circle_150{
  width: 100%;
  height: 150px;
  border-radius: 10px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}
.img_preview_circle{
  width: 120px;
  height: 120px;
  border-radius: 10px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  /* margin: 0 auto; */
}
.img_preview_circle img {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
}
.img_preview_circle_lg{
  max-width: 200px;
  width: 100%;
  height:200px;
  border-radius: 10px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}
.img_preview_circle_lg img ,
.img_preview_circle_lg video 
{
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
