.navbar_wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  background: #6e3fc6;
  padding: 8px 40px;
  z-index: 1000;
  display: flex;
}
.navbar_container {
  position: relative;
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  width: 100%;
}
.logo_container,
.navbar_menu_container,
.navbar_config_container {
  display: flex;
  align-items: center;
}

:where(.navbar_menu_container, .navbar_config_container) > ul {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
  height: 100%;
}
.navbar_menu_container > ul > li > a {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 30px;
  height: 100%;
}
.navbar_menu_container > ul > li > a.active::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    background: #fff;
    height: 2px;
    width: 24px;
    transform: translateX(-50%);
}
.navbar_config_container > ul > li > a {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 18px;
  height: 100%;
}

.logout_popover_content {
  padding: 20px;
  min-width: 200px;
}











