.auth_wrapper {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}
.auth_left_container {
  height: 100%;
  width: 100%;
  background-image: url("../../../assets/images/auth_bg_left.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.auth_welcome_box {
  width: 264px;
  height: 272px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.welcome_icon {
  margin-bottom: 2rem;
}
.welcome_text {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.auth_right_container {
  position: relative;
  height: 100%;
  width: 100%;
  background-image: url("../../../assets/images/auth_bg_right_wave.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
.goback_btn_container {
  position: absolute;
  top: 5%;
  left: 5%;
}
.auth_container {
  margin: 0 auto;
}
.auth_box {
  max-width: 327px;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0rem auto 0;
}
.auth_box_header {
  display: flex;
  flex-direction: column;
  width: 100%;
}
@media screen and (min-width: 1400px) {
  .auth_box {
    max-width: 380px;
    min-width: 360px;
  }
}
@media screen and (max-width: 1399.99px) {
  .auth_box {
    min-width: 327px;
  }
}
@media screen and (max-width: 1199.99px) {
}
@media screen and (max-width: 991.99px) {
  .auth_right_container {
    padding-top: 5rem;
  }
  .auth_container {
    width: 60%;
  }
  .auth_box_header {
    display: flex;
    align-items: center;
  }
}
@media screen and (max-width: 767.99px) {
}
@media screen and (max-width: 575.99px) {
  .auth_container {
    width: 80%;
  }
  .auth_box {
    min-width: 320px;
  }
}
@media screen and (max-width: 400px) {
  .auth_box {
    min-width: 280px;
  }
}
@media screen and (max-width: 319.99px) {
}

/* Based on Height */

@media screen and (min-height: 700px) and (min-width: 768px) {
  .auth_right_container {
    padding: 0;
    display: flex;
    justify-content: center;
    /* align-items: center; */
    padding-top: 10rem;
  }
}
@media screen and (max-height: 699.99px) {
  .auth_right_container {
    padding: 5rem 0 2rem;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }
}
