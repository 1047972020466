.gm-style-iw.gm-style-iw-c {
  width: 280px !important;
  background: #ffffff !important;
  box-shadow: 0px 0.461734px 0.923467px rgba(0, 0, 0, 0.06), 0px 0.461734px 1.3852px rgba(0, 0, 0, 0.1) !important;
  border-radius: 8px !important;
  padding: 0 !important;
  overflow: hidden !important;
}
.gm-style-iw-d {
  padding: 0 !important;
  overflow: hidden !important;
}
.gm-style-iw > button {
  display: none !important;
}
.gm-style-iw-tc {
  display: none !important;
}
.info_window_container {
  display: flex;
  align-items: stretch !important;
  height: 100% !important;
  width: 100% !important;
  min-height: 110px !important;
  min-width: 280px !important;
}
.info_window_distance_container {
  width: 30%;
  background-color: #6200ee;
  display: flex;
  justify-content: center;
  align-items: center;
}
.info_window_distance_container.person {
  background-color: #6200ee;
}
.info_window_distance_container.business {
  background-color: #00d1ee;
}
.info_window_distance_container.business_person {
  background-color: #007cee;
}
.info_window_distance_container.google {
  background-color: #34a853;
}
.info_window_distance_container.group {
  background-color: #006699;
}
.info_window_distance_container.employee {
  background-color: #007cee;
}
.info_window_info_container {
  position: relative !important;
  width: 70%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.info_window_info_container li {
  padding: 4px 12px !important;
}
.live_status_badge {
  height: 10px;
  width: 10px;
  border-radius: 50%;
}
.live_status_badge.online {
  background-color: #00cf73;
}
.live_status_badge.offline {
  background-color: #fd4a4c;
}
.search_this_area_btn_container {
  position: absolute !important;
  left: 0 !important;
  top: 15% !important;
  height: 60px !important;
  width: 100vw !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  background-color: transparent !important;
  z-index: 100 !important;
  box-shadow: none !important;
}
.search_this_area_btn {
  background: #fff !important;
  box-shadow: 0 2px 5px -1px rgba(0, 0, 0, 0.3) !important;
  color: rgb(60, 64, 67) !important;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  margin: 8px auto 0 auto;
  -webkit-box-align: center;
  -webkit-align-items: center;
  display: flex !important;
  justify-content: center !important;
  align-items: center;
  border-radius: 21px !important;
  min-width: unset !important;
  width: 150px !important;
  height: 40px !important;
}
