@import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
@import "react-phone-input-2/lib/material.css";
@import "./commonStyle/typography.css";
@import "./commonStyle/buttons.css";
@import "./commonStyle/theme.css";
@import "./commonStyle/customComponent.css";
@import "./commonStyle/muiCustomization.css";
@import "./commonStyle/formControls.css";
@import "./commonStyle/component.css";
@import "./authStyle/auth.css";
@import "./mainStyle/top-nav.css";
@import "./mainStyle/left-sidebar.css";
@import "./mainStyle/master-layout.css";
@import "./mainStyle/dashboard.css";
@import "./mainStyle/profile.css";
@import "./mainStyle/home.css";
@import "./commonStyle/mapComponent.css";
@import "./mainStyle/social.css";
@import "../../assets/fonts/stylesheet.css";
@import "~slick-carousel/slick/slick-theme.css";
@import "~slick-carousel/slick/slick.css"; 

::-webkit-scrollbar {
  background-color: transparent;
  width: 8px;
  height: 5px;
}

/* background of the scrollbar except button or resizer */
::-webkit-scrollbar-track {
  background-color: inherit;
}

/* scrollbar itself */
::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  /* border: 4px solid #fff; */
}

/* set button(top and bottom of the scrollbar) */
::-webkit-scrollbar-button {
  display: none;
}
